
<template>
    <h2>{{ msg }}</h2>
    <h3>{{ url }}</h3>
    <vue-qrcode :value=url @change="onDataUrlChange" :color="{ dark: '#000000ff', light: '#ffffffff' }"
        type='image/png' />

</template>
  
<script>
import VueQrcode from 'vue-qrcode'
export default {
    name: 'myQRcode',
    props: {
        msg: String,
        url: String
    },
    components: {
        VueQrcode,
    },
    data() {
        return {
            dataUrl: null,
        };
    },
    methods: {
        onDataUrlChange(dataUrl) {
            this.dataUrl = dataUrl
        },
    },
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
  