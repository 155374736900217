
<template>
    <h3>{{ msg }}</h3>
    <input type="url" class="valid" name="myurl" v-model="url" id="myurl">
    <h3 v-if="phone !== ''">此推荐码绑定的手机号为</h3>
    <h2> {{ phone }}</h2>
    <button class="btn-grad" @click=getMyData> 提交</button>

</template>
  
<script>
export default {
    name: 'revMyLink',
    props: {
        msg: String,
    },
    components: {

    },
    data() {
        return {
            url: '',
            phone: ''
        };
    },
    methods: {
        getMyData() {
            try {
                let url = this.url.replace('mysite', 'mydata');
                this.axios.get(url, null, { headers: { 'sign': '12344' } })
                    .then((response) => {
                        if (response.status === 200) {
                            this.phone = response.data.phone;
                        }
                    });
            } catch (err) {
                console.log(err.message);
            }
        }
    },
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
  