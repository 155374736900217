<template>
  <img alt="guiyunbao logo" src="./assets/logo.png">
  <p></p>
  <button class="btn-grad" @click="revMylink = !revMylink">推荐码校验</button>

  <RevMyLink v-if="revMylink" msg="反查链接" />
  <ReferalCode v-if="!isShowMyLink" msg="欢迎使用桂运宝推荐码生成系统" @submitUrl="showMylink" />
  <MyQRcode v-if="isShowMyLink" msg="这是您的推广链接及推广二维码" :url=mylink />
</template>

<script>
import ReferalCode from './components/ReferralCode.vue'
import MyQRcode from './components/MyQRcode.vue';
import RevMyLink from './components/RevMyLink.vue';

export default {
  name: 'App',
  components: {
    ReferalCode,
    MyQRcode,
    RevMyLink
  },
  data() {
    return {
      revMylink: false,
      mylink: "",
      isShowMyLink: false
    }
  },
  methods: {
    showMylink(link) {
      this.mylink = link;
      this.isShowMyLink = true;
    }
  }
}
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;

}

button {
  border: 0px solid #3F88C5;
  color: white;
}

button:disabled {
  pointer-events: none;
  opacity: 0.45;
}


.btn-grad {
  background-image: linear-gradient(to right, #3F88C5 0%, #3a7bd5 51%, #265999 100%);
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
}

.btn-grad:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}


input {
  display: block;
  outline: none;
  margin: 10px auto;
  border: 2px solid #eee;
  font-size: 20px;
  padding: 10px;
  background: #eee;
  border-radius: 6px;
  align-self: center;
}

.invalid {
  border: 2px solid #D00000;
}

.valid {
  border: 2px solid #136F63;
}

.invalid-warning {
  margin: 10px auto;
  color: #D00000;
}
</style>
