
<template>
  <div class="referalCode" id="referalCode">
    <h1>{{ msg }}</h1>
    <p v-if="errors.length">
      <b>以下信息未输入完全:</b>
    <ul>
      <li v-for="error in errors" :key="error.id" class="invalid-warning">{{ error }}</li>
    </ul>
    </p>
    <div id="myForm">
      <label for="phoneNumber"> 输入您的电话号码 </label>
      <input name="phoneNumber" :class="{ valid: isValidPhoneNumber, invalid: !isValidPhoneNumber }"
        v-model="phoneNumber" type="text" @keyup="validatePhoneNumber(); refreshCode()" />
      <div class="invalid-warning" v-if="!isValidPhoneNumber">
        无效的电话号码
      </div>

      <div class="sample-captcha" v-if="isValidPhoneNumber && phoneNumber != ''">
        <label for="phoneNumber"> 请输入图形里的数字 </label>
        <input type="text" v-model="inputValue" :class="{ valid: isValidCaptcha, invalid: !isValidCaptcha }"
          @keyup="validateCaptcha" />
        <div @click="refreshCode" style="cursor: pointer;" title="点击切换验证码">
          <MyCaptcha :identifyCode="identifyCode" />
        </div>

      </div>
      <button class="btn-grad" v-if="isValidCaptcha" :disabled=isSentSMS @click="sendSMS">发送验证码</button>
      <label v-if="isValidCaptcha" for="SMSCode"> 输入短信验证码 </label>
      <input v-if="isValidCaptcha" name="SMSCode" :class="{ valid: isValidSMSCode, invalid: !isValidSMSCode }"
        v-model="smsCode" type="text" @keyup="validateSMSCode" />
      <div class="invalid-warning" v-if="isValidCaptcha && !isValidSMSCode">
        无效的短信验证码
      </div>
      <p>
        <button class="btn-grad" @click="checkForm() ? getMyLink() : null">确定生成推荐码</button>
      </p>
    </div>
  </div>

</template>

<script>
import MyCaptcha from "./MyCaptcha.vue"
export default {
  name: 'referalCode',
  props: {
    msg: String
  },
  components: {
    MyCaptcha
  },
  data() {
    return {
      phoneNumber: "",
      isValidPhoneNumber: true,
      isValidSMSCode: false,
      smsCode: "",
      isSentSMS: false,
      errors: [],
      isValidCaptcha: false,
      inputValue: "",
      identifyCode: "",
      identifyCodes: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
      smsfromBE: "",
      baseUrl: process.env.VUE_APP_ROOT_API
    };
  },
  mounted() {
    this.refreshCode()
  },
  methods: {
    validatePhoneNumber() {
      const validationRegex = /^\d{11}$/;
      if (this.phoneNumber.match(validationRegex)) {
        this.isValidPhoneNumber = true;
      } else {
        this.isValidPhoneNumber = false;
      }
    },
    validateSMSCode() {
      const validationRegex = /^\d{4}$/;
      if (this.smsCode.match(validationRegex)) {
        this.isValidSMSCode = true;
      } else {
        this.isValidSMSCode = false;
      }
    },
    validateCaptcha() {
      if (this.identifyCode === this.inputValue) {
        this.isValidCaptcha = true;
      } else {
        this.isValidCaptcha = false;
      }
    },
    // 生成随机数
    randomNum(min, max) {
      max = max + 1
      return Math.floor(Math.random() * (max - min) + min);
    },
    // 切换验证码
    refreshCode() {
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 4)
    },
    // 随机生成验证码字符串
    makeCode(data, len) {
      for (let i = 0; i < len; i++) {
        this.identifyCode += this.identifyCodes[this.randomNum(0, this.identifyCodes.length - 1)]
      }
    },
    getMyLink() {
      if (this.isValidPhoneNumber && this.isValidCaptcha) {
        const data = this.$CryptoJS.AES.encrypt(`salesman${this.phoneNumber}`, process.env.VUE_APP_CRYPTOJSKEY).toString();
        const headers = { 'sign': data };
        let url = `${process.env.VUE_APP_ROOT_API}/salesman/mylink`;
        try {
          this.axios.get(url, { headers })
            .then((response) => {
              if (response.status === 200) {
                this.$emit("submitUrl", response.data.url);
              }
            });
        } catch (err) {
          console.log(err.message);
        }
      }
    },

    sendSMS() {
      const data = this.$CryptoJS.AES.encrypt(`sendSMS${this.phoneNumber}`, process.env.VUE_APP_CRYPTOJSKEY).toString();
      const headers = { 'sign': data };
      this.isSentSMS = true;

      try {
        this.axios.get(process.env.VUE_APP_ROOT_API, { headers }).then((response) => {
          if (response.status === 200) {
            this.smsfromBE = response.data.code;
            setTimeout(() => { this.isSentSMS = false }, 30.0 * 1000);
          }
        });

      } catch (err) {
        console.log(err.message);
      }
    },


    checkForm: function () {
      if (this.isValidPhoneNumber && this.isValidCaptcha && this.isValidSMSCode && this.smsCode == this.smsfromBE) {
        return true;
      }
      this.errors = [];
      if (!this.isValidPhoneNumber) {
        this.errors.push('电话号码不能为空！');
      }
      if (!this.isValidCaptcha) {
        this.errors.push('请输入图形验证码');
      }
      if (this.isValidCaptcha && this.SMSCode === '') {
        this.errors.push('短信验证码不能为空');
      }
      if (this.isValidCaptcha && this.smsCode != this.smsfromBE) {
        this.errors.push('验证码错误，请重试');
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#referalCode {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 24px;
}

label {
  font-size: 20px;
  display: block;
  margin: 10px auto;
  font-weight: 600;
}

ul {
  list-style-type: none;
}

input {
  display: block;
  outline: none;
  margin: 10px auto;
  border: 2px solid #eee;
  font-size: 20px;
  padding: 10px;
  background: #eee;
  border-radius: 6px;
  align-self: center;
}

.invalid {
  border: 2px solid #D00000;
}

.valid {
  border: 2px solid #136F63;
}

.invalid-warning {
  margin: 10px auto;
  color: #D00000;
}
</style>
