import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCryptojs from 'vue-cryptojs'

const app = createApp(App);
app.use(VueAxios, axios);
app.use(VueCryptojs);
app.mount('#app');
